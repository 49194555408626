import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import useScript from "../../utils/useScript";
import { config } from "../../config";
import RempePrescriptionIframe from "../rempe";
import ErrorView, { SetupErrorCode } from "./_components/ErrorView";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const url = config.services.mediquo.chat_api_url;
const osigu_url = config.services.osigu.prescription_widget_url;

const locale =
	i18n.language === "pt-BR" ? i18n.language : i18n.language.split("-")[0];

const getCredentials = async ({ jwt, roomId }) => {
	const response = await axios.all([
		axios.get(`${url}/professionals/v1/prescription-provider`, {
			headers: {
				Authorization: `Bearer ${jwt}`,
			},
		}),
		axios.get(`${url}/professionals/v1/rooms/${roomId}`, {
			headers: {
				Authorization: `Bearer ${jwt}`,
			},
		}),
	]);

	const prescriptionProviders = response[0].data.data;
	const room = response[1].data.data;

	const setup = await axios.get(`${url}/professionals/v2/prescriptions/setup`, {
		params: {
			contact_id: room.meta.contact_id,
			patient_hash: room.meta.hash,
		},
		headers: {
			Authorization: `Bearer ${jwt}`,
		},
	});

	const providerName = setup.data.data.provider_name;
	const credentials = prescriptionProviders.filter(
		(credential) => credential.provider === providerName
	)[0];

	return {
		setup: setup.data.data,
		credentials: credentials,
		profile: room,
	};
};

const handleOnPrescriptionComplete =
	({ setup, jwt, roomId, consultationId, consultationType }) =>
	async ({ detail }) => {
		await axios.post(
			`${url}/professionals/v1/rooms/${roomId}/prescriptions`,
			{
				data: detail,
				provider: setup.setup.provider_name,
				consultation_id: consultationId,
				consultation_type: consultationType,
			},
			{
				headers: {
					Authorization: `Bearer ${jwt}`,
				},
			}
		);

		window.location.replace("/eprescription-success");
	};

const MAX_CHARACTERS_ALLOWED = 50;

const Eprescription = () => {
	const location = useLocation();
	const params = useMemo(
		() => new URLSearchParams(location.search),
		[location]
	);
	const status = useScript(osigu_url);
	const [setup, setSetup] = useState();
	const [isRempe, setIsRempe] = useState(false);
	const [error, setError] = useState();
	const { t } = useTranslation();

	useEffect(() => {
		if (status !== "ready") {
			return;
		}

		getCredentials({
			jwt: params.get("jwt"),
			roomId: params.get("room_id"),
		})
			.then((setup) => {
				if (setup.setup?.provider === "rempe") {
					setSetup(setup);
					setIsRempe(true);
					return;
				}

				const doctor = JSON.parse(setup.credentials?.data);

				const [firstName, ...lastName] = setup.profile.title.split(" ");

				let config = {
					containerId: "osigu_root",
					clientId: setup.setup.client_id,
					clientSecret: setup.setup.client_secret,
					primaryColor: "#4A1EA7",
					errorColor: "#FF014D",
					locale: locale,
					doctor: {
						countryCode: doctor.countryCode.toUpperCase(),
						medicalLicenseNumber: doctor.medicalLicenseNumber,
					},
					patient: {
						givenNames: firstName?.slice(0, MAX_CHARACTERS_ALLOWED),
						surnames: lastName?.join(" ").slice(0, MAX_CHARACTERS_ALLOWED),
						nationality: setup.profile.meta.country_code?.toUpperCase(),
						//areaCode: countryCallingCode ? `+${countryCallingCode}` : undefined,

						dateOfBirth: setup.profile.meta.birth_date,
						emailAddress: setup.profile.meta.email,
						sex: { 0: "MALE", 1: "FEMALE" }[setup.profile.meta.gender],
					},
					template: setup.setup.template,
				};

				if (setup.setup.meta.provider_slug) {
					config.providerSlugs = [setup.setup.meta.provider_slug];
				}

				if (setup.setup.meta.customization.observations) {
					config.prescriptionObservation = setup.setup.meta.customization.observations;
				}

				if (doctor.countryCode?.toLowerCase() !== "es") {
					config.enabledFeatures = ["diagnoses"];
				}

				window.EPrescriptionWidget.createWidget(config);

				const container = document.getElementById("osigu_root");

				const prescriptionSetup = {
					setup,
					jwt: params.get("jwt"),
					roomId: params.get("room_id"),
					consultationId: params.get("consultation_id"),
					consultationType: params.get("consultation_type"),
				};
				if (doctor.countryCode?.toLowerCase() !== "br") {
					container.addEventListener(
						"onPrescriptionComplete",
						handleOnPrescriptionComplete(prescriptionSetup)
					);
				} else {
					container.addEventListener(
						"onExternalSignature:success",
						handleOnPrescriptionComplete(prescriptionSetup)
					);
					container.addEventListener(
						"onExternalSignature:end",
						handleOnPrescriptionComplete(prescriptionSetup)
					);
				}
			})
			.catch((err) => {
				setError(err);
			});
	}, [status, params]);

	if (error && axios.isAxiosError(error)) {
		return (
			<ErrorView
				errorCode={
					error.response?.data?.errorCode ?? SetupErrorCode.GeneralError
				}
			/>
		);
	}

	if (isRempe) {
		return (
			<main>
				<div className="h-screen">
					<RempePrescriptionIframe
						jwt={params.get("jwt")}
						credentials={setup.setup}
						consultationId={params.get("consultation_id")}
						consultationType={params.get("consultation_type")}
						room={setup.profile}
					/>
				</div>
			</main>
		);
	}

	if (!params.has("jwt")) {
		return (
			<Page>
				<h1 className="text-primary font-medium text-2xl">
					{t("landing__page_not_found_title")}
				</h1>
			</Page>
		);
	}

	return (
		<main>
			{status !== "ready" && (
				<Page>
					<h1 className="text-primary font-medium text-2xl">
						{t("landing__page_loading_title")}
					</h1>
				</Page>
			)}
			<div id="osigu_root"></div>
		</main>
	);
};

const Page = ({ children }) => (
	<div className="grid content-center h-screen">
		<div className="flex flex-col items-center justify-center">{children}</div>
	</div>
);

export default Eprescription;
